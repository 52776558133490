import SmoothScroll from 'smooth-scroll';
import debounce from 'debounce';

/**
 * Create an element from HTML.
 *
 * @see https://stackoverflow.com/a/494348/1136822
 * @param {string} htmlString
 * @return {node|null}
 */
const createElementFromHTML = function(htmlString) {
	let div = document.createElement('div');
	div.innerHTML = htmlString.trim();

	// Change this to div.childNodes to support multiple top-level nodes
	return div.firstChild;
};

/**!
 * Top Link
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
export default {
	/**
	 * Creates the dropdown link element and initializes it.
	 *
	 * @param {string} htmlString
	 * @param {object} options
	 */
	initFromHtml(htmlString, options = {}, parentSelector = 'body') {
		let el = createElementFromHTML(htmlString);
		let parent = document.querySelector(parentSelector);
		parent.appendChild(el);
		this.init(el, options);
	},
	/**
	 * Initializes the dropdown link from an element.
	 *
	 * @param {node} el
	 * @param {string} baseClass
	 * @param {string} inClass
	 * @param {string} outClass
	 * @param {int} topDistance
	 * @param {int} speed
	 * @param {int} offset
	 */
	init(el, {
		baseClass = 'animated',
		inClass = 'fadeIn',
		outClass = 'fadeOut',
		topDistance = 1,
		speed = 500,
		offset = 0
	} = {}) {

		//add the base class
		if (!!baseClass) {
			el.classList.add(baseClass);
		}

		//listen for scroll
		window.addEventListener('scroll', debounce(function(){
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			if (scrollTop >= topDistance) {
				el.classList.remove(outClass);
				el.classList.add(inClass);
				el.inert = false;
			} else {
				el.classList.remove(inClass);
				el.classList.add(outClass);
				el.inert = true
			}
		}), 10, false);

		//set up smooth scroll
		let scroller = new SmoothScroll();

		//listen for click
		el.addEventListener('click', e => {
			e.preventDefault();
			scroller.animateScroll(0, null, {
				speed: speed,
				offset: offset
			});
		});
	}
}
