const isSessionStorageSupported = (function () {
    const test = 'test'
    try {
        sessionStorage.setItem(test, test)
        sessionStorage.removeItem(test)
        return true
    } catch (e) {
        return false
    }
})()

export default isSessionStorageSupported
