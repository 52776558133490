import SmoothScroll from 'smooth-scroll';

let hashListenerIsSetUp = false;

/**!
 * Smooth Scroll
 *
 * Sets up automatic in-page smooth scroll targets
 * and specified data targets.
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
export default {
	/**
	 * Sets up auto scroll and data targets at the same time.
	 *
	 * @param {int} speed The scroll speed.
	 * @param {int} offset The scroll offset.
	 * @param {string|null} scrollHeader The header selector.
	 */
	init(speed = 300, offset = 0, scrollHeader = null) {
		this.setUpAutoScroll(speed, offset, scrollHeader);

		if (!hashListenerIsSetUp) {
			hashListenerIsSetUp = true;

			let hashHandler = function(){
				let theHash = window.location.hash.substring(1);
				if (!theHash) {
					return;
				}

				try {
					let hashEl = document.querySelector('#'+theHash);
					if (hashEl) {
						//scroll there
						let scroller = new SmoothScroll();
						scroller.animateScroll(hashEl, null, {
							speed: speed,
							offset() {
								return offset;
							},
							header: scrollHeader
						});
					}
				} catch (e) {
					console.log(e);
				}
			};
			window.addEventListener("hashchange", hashHandler);
			window.addEventListener("load", hashHandler);
		}
	},
	/**
	 * Sets up auto scroll.
	 *
	 * Add the `data-scroll-ignore` attribute to links that you want to ignore.
	 *
	 * @param {int} speed The scroll speed.
	 * @param {int} offset The scroll offset.
	 * @param {string|null} scrollHeader The scroll header selector.
	 */
	setUpAutoScroll(speed = 300, offset = 0, scrollHeader = null) {
		const currentPage = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
		const currentPageRelative = window.location.pathname;
		new SmoothScroll('a[href^="#"]:not([data-scroll-ignore]):not([href="#"]), a[href^="' + currentPage + '#"]:not([data-scroll-ignore]):not([href="#"]), [href^="' + currentPageRelative + '#"]:not([data-scroll-ignore])', {
			speed: speed,
			offset() {
				return offset;
			},
			header: scrollHeader
		});
	}
};
