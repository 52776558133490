let apps = [];

export default {
	init(selector = '[data-timeline]') {
		let els = document.querySelectorAll(selector);
		if (els.length) {
			import(/*webpackChunkName: "timeline"*/ './timeline').then(({default:Timeline}) => {
				els.forEach(el => {
					let app = new Timeline(el);
					apps.push(app);
				});
			});
		}
	},
	destroy() {
		apps.forEach(app => {
			
		});
		apps = [];
	}
}
