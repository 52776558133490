import isSessionStorageSupported from "./isSessionStorageSupported";

const isDebugMode = false
const storageValue = 'y'

export default class Alert {
	constructor(el, {
		            idAttribute = 'data-alert',
		            closeSelector = '[data-alert-close]',
					initAttribute = 'data-alert-ready'
	            } = {}) {

		if (el.hasAttribute(initAttribute)) {
			return
		}

		const id = el.getAttribute(idAttribute)
		const storageKey = `alert-dismissed-${id ?? ''}`

		let shouldShow = false

		if (id) {
			if (
				isDebugMode ||
				sessionStorage.getItem(storageKey) !== storageValue
			) {
				// we have an id, but no record of the alert being closed
				shouldShow = true
			}
		} else {
			// no id, show the alert
			shouldShow = true
		}

		if (!shouldShow) {
			return
		}

		el.setAttribute(initAttribute, '')

		// unhide the alert
		el.hidden = false

		const closeButtons = el.querySelectorAll(
			closeSelector
		)
		closeButtons.forEach((closeButton) => {
			closeButton.addEventListener('click', (e) => {
				e.preventDefault()
				el.hidden = true

				// if we have an id and support session storage, remember this was dismissed
				if (id && isSessionStorageSupported) {
					sessionStorage.setItem(storageKey, storageValue)
				}
			})
		})
	}
}
