// ---------- Config Setup ----------
const SITE = window.SITE || {};
//const isDevMode = SITE.devMode || false;
//const isProduction = SITE.isProduction || false;
global.isAdminLoggedIn = SITE.isAdminLoggedIn || false;
global.mobileMenuMaxWidth = SITE.mobileMenuMaxWidth || 0;

// ---------- Variables ----------
const scrollSpeed = 500;
const scrollOffset = 20;
const scrollHeader = '[data-scroll-header]';
const html = document.querySelector('html');

// ---------- Polyfills ----------
import 'svgxuse';
import 'focus-visible/dist/focus-visible';
import 'wicg-inert/dist/inert';
import 'element-qsa-scope';
import 'focus-within';

// ---------- js class ----------
import './Components/jsClass';

// ---------- ie11 class ----------
import './Components/ie11Class';

// ---------- lazysizes ----------
import lazySizes from 'lazysizes';
//import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
Object.assign(lazySizes.cfg, {loadMode:1});

// ---------- smooth scroll ----------
import SmoothScrollLinks from './Components/smoothScroll';
SmoothScrollLinks.init(scrollSpeed, scrollOffset, scrollHeader);

// ---------- scroll indicator ----------
import DownLink from './Components/downLink';
let downLink = new DownLink('<div class="scrollLink-wrapper"><button class="scrollLink" aria-label="Scroll down"><svg class="icon icon-angle-down" aria-hidden="true"><use href="#angle-down"></use></svg><span>Scroll</span></button></div>', {speed:scrollSpeed}, '.footer');

// ---------- back to top button ----------
import topLink from './Components/topLink';
topLink.initFromHtml('<div class="topLink-wrapper"><button class="topLink" aria-label="Scroll to top"><svg class="icon icon-angle-up"><use href="#angle-up" aria-label="hidden"></use></svg><span>Top</span></button></div>', {speed:scrollSpeed, topDistance:400}, '.footer');

// ---------- floating header ----------
import floating from './Components/floatingElement';
floating.init();

// ---------- sliders ----------
import flickitySliders from './Components/flickitySliders';

// ---------- mobile menu ----------
import MobileMenu from './Components/mobileMenu';
const mobileNav = new MobileMenu({
	openClass: 'mobileNav-isOpen',
	closeClass: 'mobileNav-isClosed',
	toggleSelector: '[data-nav-toggle]',
	maxWidth: 1600,
	onOpen() {
		//make the main and footer elements inert
		document.querySelectorAll('.main, .footer').forEach(item => {
			item.inert = true;
		});

		this.mobileMenuToggles.forEach(mobileMenuToggle => {
			//set the aria expanded attributes
			mobileMenuToggle.setAttribute('aria-expanded', true);

			//set the aria label attribute
			mobileMenuToggle.setAttribute('aria-label', 'Close menu');
		});
	},
	onClose() {
		//make the main and footer elements not inert
		document.querySelectorAll('.main, .footer').forEach(item => {
			item.inert = false;
		});
		this.mobileMenuToggles.forEach(mobileMenuToggle => {
			//set the aria expanded attributes
			mobileMenuToggle.setAttribute('aria-expanded', false);

			//set the aria label attribute
			mobileMenuToggle.setAttribute('aria-label', 'Open Menu');
		});
	}
});
mobileNav.close();

// ---------- multi-level nav ----------
import MultiLevelNav from './Components/multiLevelNav';

// ---------- svg sprite ----------
import './Components/svgSprite';

// ---------- responsive videos ----------
import responsiveVideos from './Components/responsiveVideos';

// ---------- hidden videos ----------
import hiddenVideos from './Components/hideVideosUntilPlay';

// ---------- lightbox ----------
import MediaLightbox from './Components/lightbox';
let holder = document.querySelector('.holder');
let lightbox = new MediaLightbox({
	onOpen() {
		if (holder) {
			holder.inert = true;
		}
	},
	onClose() {
		if (holder) {
			holder.inert = false;
		}
	}
});
window.lightbox = lightbox;

// ---------- scrollbar compensation ----------
import scrollbarCompensation from './Components/scrollbarCompensation';
scrollbarCompensation();

// ---------- mobile vh fix ----------
import mobileVh from './Components/mobileVh';
mobileVh();

// ---------- top bar ----------
import topbar from 'topbar';
topbar.config({
	barColors: {0:'rgba(206, 14, 45, 1)', 1:'rgba(206, 14, 45, 1)'},
	shadowBlur: 0,
	shadowColor  : 'rgba(0, 0, 0, 0)'
});

// ---------- video cover (for background video) ----------
import videoCover from './Components/videoCover';

// ---------- tabs ----------
import tabs from './Components/tabs';

// ---------- timeline ----------
import timelineLoader from './Components/timeline/loader';

// ---------- forms ----------
import forms from './Components/forms';

// ---------- alert ----------
import Alert from './Components/alert';

// ---------- aos ----------
import aos from './Components/aos';

// ---------- nav active state updater ----------
import updateNav from './Components/updateNav';

import ContentLightbox from './Components/contentLightbox';
let contentLightBoxHtmlClass = 'contentLightbox-isOpen';
let contentLightBox = new ContentLightbox({
	onOpen() {
		html.classList.add(contentLightBoxHtmlClass);
	},
	onClose() {
		html.classList.remove(contentLightBoxHtmlClass);
	}
});

// ---------- on page load ----------
const onPageLoad = function(isInitialLoad = false) {

	// ---------- nav ----------
	updateNav();

	if (isInitialLoad){
		new MultiLevelNav('.mobileNav-main', {
			backButtonClass: '',
			backButtonText: '<svg class="icon icon-angle-left" aria-hidden="true"><use href="#angle-left"></use></svg> Back To {levelName}'
		});
	}

	// ---------- csrf tokens ----------
	window.csrfTokenName = document.querySelector('meta[name="csrf-name"]').getAttribute('content');
	window.csrfTokenValue = document.querySelector('meta[name="csrf-value"]').getAttribute('content');

	// ---------- scroll indicator ----------
	downLink.reset();

	// ---------- sliders ----------
	if (!isInitialLoad) {
		flickitySliders.destroy();
	}
	flickitySliders.init();

	// ---------- video cover ----------
	if (!isInitialLoad) {
		videoCover.destroy();
	}
	videoCover.init();

	// ---------- responsive videos ----------
	if (!isInitialLoad) {
		responsiveVideos.destroy();
	}
	responsiveVideos.init();

	// ---------- hidden videos ----------
	if (!isInitialLoad) {
		hiddenVideos.destroy();
	}
	hiddenVideos.init();

	// ---------- tabs ----------
	if (!isInitialLoad) {
		tabs.destroy();
	}
	tabs.init();

	// ---------- content lightbox ----------
	if (!isInitialLoad) {
		contentLightBox.destroy();
	}
	contentLightBox.init();

	// ---------- lightbox ----------
	lightbox.init();
	if (!isInitialLoad) {
		//attempt to focus the page's h1
		let h1El = document.querySelector('h1');
		if (h1El) {
			h1El.tabIndex = -1;
			h1El.focus();
		}
	}

	// ---------- search ----------
	let searchBox = document.querySelector('#search-box');
	if (searchBox) {
		//attempt to focus search if on the search page
		searchBox.focus();
	}

	// ---------- timeline ----------
	if (!isInitialLoad) {
		timelineLoader.destroy();
	}
	timelineLoader.init();

	// ---------- forms ----------
	if (!isInitialLoad) {
		forms.destroy();
	}
	forms.init();

	// ---------- alerts ----------
	let alertEls = document.querySelectorAll('[data-alert]');
	if (alertEls.length) {
		alertEls.forEach(alertEl => {
			new Alert(alertEl)
		})
	}

	// ---------- aos ----------
	aos.init();
};
onPageLoad(true);

document.addEventListener('pjax:success', () => {
	onPageLoad(false);
});
document.addEventListener('pjax:send', () => {
	// ---------- nav ----------
	mobileNav.close();

	// ---------- lightbox ----------
	lightbox.close();

	// ---------- topbar ----------
	topbar.show();
});

document.addEventListener('pjax:complete', topbar.hide);

// ---------- pjax ----------
//set up PJAX for everything except for IE 11
const isIE11 = !!(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/));
if (!isIE11) {
	import(/*webpackChunkName: "pjax"*/ 'pjax').then(({default:Pjax}) => {
		new Pjax({
			elements: 'a[href]:not(.no-pjax):not([href*=".gif"]):not([href*=".jpg"]):not([href*=".png"]):not([href*=".pdf"]):not([href*=".json"]), form[method="get"]',
			selectors: [
				'head > title',
				'meta[name="description"]',
				'meta[name="csrf-name"]',
				'meta[name="csrf-value"]',
				'.main'
			],
			cacheBust: false
		});
	});
}
