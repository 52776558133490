import debounce from 'debounce';

let htmlEl = document.querySelector('html');

export default {
	init(selectorAttr='data-float-distance', toggleClass='floating', toggleDomClass='navIsFloating') {
		let els = document.querySelectorAll('['+selectorAttr+']');
		if (els.length) {
			els.forEach(el => {
				let distance = el.getAttribute(selectorAttr);
				this.setUp(el,distance,toggleClass, toggleDomClass);
			});
		}
	},
	setUp(el, distance=100, toggleClass='floating', toggleDomClass='navIsFloating') {
		let toggleFloatingClass = function() {
			let scrollTop  = window.pageYOffset || document.documentElement.scrollTop;
			if (scrollTop >= distance) {
				el.classList.add(toggleClass);

				if (htmlEl && toggleDomClass) {
					htmlEl.classList.add(toggleDomClass);
				}
			} else {
				el.classList.remove(toggleClass);

				if (htmlEl && toggleDomClass) {
					htmlEl.classList.remove(toggleDomClass);
				}
			}
		};

		['DOMContentLoaded', 'load', 'resize', 'scroll'].forEach(function(eventName) {
			window.addEventListener(eventName, debounce(toggleFloatingClass, 10, false));
		});
	}
}
