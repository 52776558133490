// ---------- Flickity Slider ----------

//see https://flickity.metafizzy.co

const isIE11 = !!(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/));

import Flickity from 'flickity-as-nav-for';

//Flickity equal cell height hack
//see https://codepen.io/desandro/pen/ZXEGVq
Flickity.prototype._createResizeClass = function() {
	this.element.classList.add('flickity-ready');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
	this.element.classList.remove('flickity-ready');
	resize.call( this );
	this.element.classList.add('flickity-ready');
};

let initiatedSliders = [];

export default {
	init({
		     sliderSelector = '[data-flickity-slider]',
		     attribute = 'data-flickity-slider',
		     slideSelector = '.slide'
	     } = {}) {

		let flickitySliders = document.querySelectorAll(sliderSelector);
		if (flickitySliders.length) {
			flickitySliders.forEach(flickitySlider => {
				let sliderType = flickitySlider.getAttribute(attribute);

				let slides = flickitySlider.querySelectorAll(slideSelector);

				//default settings
				let settings = {
					autoPlay: false,
					contain: true,
					pageDots: false,
					prevNextButtons: slides.length > 1,
					wrapAround: true
				};

				if (sliderType === 'slides') {
					settings = {
						autoPlay: false,
						pauseAutoPlayOnHover: false,
						contain: false,
						pageDots: true,
						prevNextButtons: true,
						wrapAround: true,
						arrowShape: 'M68.317,8.317c0,0.868 -0.334,1.636 -1.002,2.304l-39.379,39.379l39.379,39.379c0.668,0.668 1.002,1.436 1.002,2.304c0,0.869 -0.334,1.637 -1.002,2.305l-5.01,5.01c-0.668,0.668 -1.437,1.002 -2.305,1.002c-0.868,0 -1.637,-0.334 -2.305,-1.002l-46.693,-46.693c-0.668,-0.668 -1.002,-1.437 -1.002,-2.305c0,-0.868 0.334,-1.637 1.002,-2.305l46.693,-46.693c0.668,-0.668 1.437,-1.002 2.305,-1.002c0.868,0 1.637,0.334 2.305,1.002l5.01,5.01c0.668,0.668 1.002,1.436 1.002,2.305Z'
					};
				} else if (sliderType === 'slidesBg') {
					let sliderId = flickitySlider.getAttribute('data-as-nav-for');
					if (!!sliderId) {
						let sliderEl = document.querySelector(sliderId);
						if (sliderEl) {
							settings = {
								autoPlay: false,
								pauseAutoPlayOnHover: false,
								contain: false,
								pageDots: false,
								prevNextButtons: false,
								wrapAround: false,
								draggable: false,
								asNavFor: sliderEl
							};
						}
					}
				}

				//set up the main slider
				let slider = new Flickity(flickitySlider, settings);
				initiatedSliders.push(slider);

				//trigger a resize for the "slides" slider
				if (sliderType === 'slides') {
					slider.resize();
				}
			});
		}
	},
	destroy() {
		if (initiatedSliders.length) {
			initiatedSliders.forEach(initiatedSlider => {
				if (isIE11) {
					initiatedSlider = null;
				} else {
					try {
						initiatedSlider.destroy();
					} catch (e) {
						console.log('flickity destroy error', e);
					}
				}
			});
		}
		initiatedSliders = [];
	}
}
