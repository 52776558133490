/**!
 * isIE11
 *
 * @see https://stackoverflow.com/a/17447374/1136822
 */
const isIE11 = !!(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/));
if (isIE11) {
	let html = document.querySelector('html');
	html.classList.add('is-ie11');
}
