import ListenerManager from './listenerManager';
let listenerBoss = new ListenerManager();

const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

export default {
	init(selector = '[data-hidden-video]', readyClass = 'video-ready') {
		let els = document.querySelectorAll(selector);
		if (els.length) {
			els.forEach(el => {
				//show the video and then remove the callback
				const showVideo = function() {
					el.classList.add(readyClass);
					listenerBoss.remove(el,'playing',showVideo);
				};

				//show the video when the video starts playing
				listenerBoss.register(el,'playing', showVideo);

				//see if the video is currently playing
				if (isVideoPlaying(el)) {
					showVideo();
				}
			});
		}
	},
	destroy() {
		listenerBoss.removeAll();
	}
}
