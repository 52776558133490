import ListenerManager from './listenerManager';

let listenerBoss = new ListenerManager();

/**
 * Takes an element or selector string and returns an element or false.
 *
 * @param elOrSelector
 * @return {object|false}
 * @private
 */
const getElement = function(elOrSelector) {
	let el = elOrSelector;

	//make sure we have an element or selector
	if (!el){
		return false;
	}

	//find the element if we have a selector
	if (typeof(el) === 'string') {
		el = document.querySelector(el);
		if (!el){
			return false;
		}
	}

	//make sure we have a node
	if (!el.nodeType) {
		return false;
	}

	return el;
};

export default class MultiLevelNav {

	//---------- private properties ----------
	_outerEl = null;
	_innerEl = null;
	_baseEl = null;
	_isInitialized = false;
	_readyCallback = function(){};
	_levelChangeCallback = function(){};
	_outerClass;
	_innerClass;
	_levelClass;
	_currentLevelClass;
	_baseLevelClass;
	_addedLevelClass;
	_hasChildrenClass;
	_backClass;
	_backButtonClass;
	_backButtonText;
	_selectedClass;
	_currentLevel = 0;
	_isDrilledDown = false;

	constructor(elOrSelector = null, options = {}){
		//make sure we have a valid element
		let el = getElement(elOrSelector);
		if (!el){
			throw "Element not found.";
		}
		if(el.tagName.toLowerCase() !== 'ul'){
			throw "Element must be a 'ul' tag.";
		}
		this._init(el, options);
	}

	_init(el, {
		onReady = function(){},
		onLevelChange = function(){},
		initClass = 'multiLevel',
		innerClass = 'multiLevel-inner',
		levelClass = 'multiLevel-level',
		currentLevelClass = 'multiLevel-level--current',
		baseLevelClass = 'multiLevel-base',
		addedLevelClass = 'multiLevel-added',
		hasChildrenClass = 'multiLevel-hasChildren',
		backClass = 'multiLevel-back',
		backButtonClass = 'button',
		backButtonText = 'Back To {levelName}',
		selectedClass = 'selected'
	} = options) {
		//set class properties
		this._readyCallback = onReady;
		this._levelChangeCallback = onLevelChange;
		this._outerClass = initClass;
		this._innerClass = innerClass;
		this._levelClass = levelClass;
		this._currentLevelClass = currentLevelClass;
		this._baseLevelClass = baseLevelClass;
		this._addedLevelClass = addedLevelClass;
		this._hasChildrenClass = hasChildrenClass;
		this._backClass = backClass;
		this._backButtonClass = backButtonClass;
		this._backButtonText = backButtonText;
		this._selectedClass = selectedClass;

		//set up the structure
		this._setUpHtmlStructure(el);

		//add the listeners
		this._addListeners();

		//resize
		this._setPosition();

		//drill down to the selected level
		this._clickSelectedLink();

		this._isInitialized = true;
	}

	//---------- private methods ----------
	/**
	 * Sets up the link levels. Gives the appropriate links a
	 * data-level= attribute.
	 *
	 * @param ulEl
	 * @param level
	 * @private
	 */
	_setUpLinkLevels(ulEl, level = 0){
		level += 1;

		let liEls = ulEl.querySelectorAll(':scope > li');
		if (!liEls.length){
			return;
		}
		liEls.forEach(liEl => {
			let childUlEl = liEl.querySelector(':scope > ul');
			if (childUlEl) {
				liEl.classList.add(this._hasChildrenClass);
				let childLink = liEl.querySelector(':scope > a');
				if (childLink){
					childLink.setAttribute('data-level', level);
				}

				this._setUpLinkLevels(childUlEl, level);
			}
		})
	}

	/**
	 * Sets up the structure.
	 *
	 * @param el
	 * @private
	 */
	_setUpHtmlStructure(el){
		//set up the link levels
		this._setUpLinkLevels(el);

		///set up the base el
		this._baseEl = document.createElement('div');
		this._baseEl.classList.add(this._baseLevelClass, this._levelClass);
		this._baseEl.innerHTML = '<ul>'+el.innerHTML+'</ul>';

		//set up the inner el
		this._innerEl = document.createElement('div');
		this._innerEl.classList.add(this._innerClass);
		this._innerEl.appendChild(this._baseEl);

		//set up the outer el
		this._outerEl = document.createElement('div');
		this._outerEl.classList.add(this._outerClass);
		this._outerEl.appendChild(this._innerEl);

		//add the outer el before the original el and then remove the original
		el.parentNode.insertBefore(this._outerEl,el);
		el.parentNode.removeChild(el);
	}

	/**
	 * Adds the link and button listeners.
	 *
	 * @private
	 */
	_addListeners() {
		let that = this;

		//add the link & button click events
		listenerBoss.register(this._innerEl, 'click', function (e) {
			if (e.target.matches('a[data-level]')) { //did this match a link?
				that._goToNextLevel(e.target);
				e.preventDefault();
				e.stopPropagation();
			} else if (e.target.matches('button')) { //did this match a button?
				that._goToPrevLevel();
				e.preventDefault();
				e.stopPropagation();
			}
		}, {capture:true});
	}

	/**
	 * Clicks the selected link so the nav will start on the
	 * appropriate page.
	 *
	 * @private
	 */
	_clickSelectedLink(){
		if (this._selectedClass) {
			if (!this._isDrilledDown) {
				let levelEls = this._innerEl.querySelectorAll('.'+this._levelClass);
				let currentLevelEl = levelEls[this._currentLevel];
				let currentLink = currentLevelEl.querySelector(':scope > ul > li > a[data-level].'+this._selectedClass);
				if (currentLink) {
					currentLink.click();
				} else {
					this._isDrilledDown = true;
				}
			}
		} else {
			this._isDrilledDown = true;
		}
	}

	/**
	 * Go to the next level.
	 *
	 * @param clickedLink
	 * @private
	 */
	_goToNextLevel(clickedLink){
		//update the current level
		let level = parseInt(clickedLink.getAttribute('data-level'));
		this._currentLevel = level;

		//remove any later levels
		let levelEls = this._innerEl.querySelectorAll('.'+this._levelClass);
		levelEls.forEach((levelEl, levelInd) => {
			if (levelInd >= level) {
				this._innerEl.removeChild(levelEl);
			}
		});

		let previousText = 'Main Menu';
		if (level > 1){
			previousText = 'Previous';
			let parentAddedLevel = clickedLink.closest('.'+this._addedLevelClass);
			let levelLink = parentAddedLevel.querySelector(':scope > a');
			if (levelLink){
				previousText = levelLink.textContent;
			}
		}

		let backButtonText = this._backButtonText.replace('{levelName}',previousText);

		//add the level
		let newLevelEl = document.createElement('div');
		newLevelEl.classList.add(this._levelClass, this._addedLevelClass);
		newLevelEl.innerHTML = '<div class="'+this._backClass+'"><button class="'+this._backButtonClass+'" data-back="'+(level - 1)+'">'+backButtonText+'</button></div>'
			+ clickedLink.closest('li').innerHTML;
		this._innerEl.appendChild(newLevelEl);

		//remove the main links data-level attribute (so it can be clicked)
		newLevelEl.querySelector('a[data-level="'+level+'"]').removeAttribute('data-level');

		//resize
		this._setPosition();

		//drill down to the selected level
		this._clickSelectedLink();
	}

	/**
	 * Go to the previous level.
	 *
	 * @private
	 */
	_goToPrevLevel(){
		//don't go past the base level
		if (!this._currentLevel) {
			return;
		}
		this._currentLevel -= 1;

		//deal with later levels
		let levelEls = this._innerEl.querySelectorAll('.'+this._levelClass);
		levelEls.forEach((levelEl, levelInd) => {
			if (levelInd > this._currentLevel + 1) { //remove deep levels
				this._innerEl.removeChild(levelEl);
			}
		});

		//resize
		this._setPosition();
	}

	/**
	 * Positions the elements.
	 *
	 * @private
	 */
	_setPosition(){
		//find all of the levels
		let levelEls = this._innerEl.querySelectorAll('.'+this._levelClass);

		//determine the total number of levels
		let totalLevels = levelEls.length;

		//set the position and width of the inner div
		this._innerEl.style.width = (totalLevels * 100)+'%';
		this._innerEl.style.left = (this._currentLevel * -100)+'%';

		//set the width of the level divs
		let levelWidth = Math.round(100 / totalLevels);
		levelEls.forEach((levelEl, levelInd) => {
			levelEl.style.width = levelWidth+'%';
			levelEl.style.left = (levelInd*levelWidth)+'%';

			//mark divs as current or inert as appropriate
			if (levelInd === this._currentLevel) {
				levelEl.classList.add(this._currentLevelClass);
				levelEl.inert = false;
			} else {
				levelEl.classList.remove(this._currentLevelClass);
				levelEl.inert = true;
			}
		});
	}
}
