import ListenerManager from './listenerManager';
let listenerBoss = new ListenerManager();

export default {
	init(selector = '[data-tabs]', selectedClass = 'selected') {
		let els = document.querySelectorAll(selector);
		if (els.length) {
			els.forEach(el => {
				let tabButtons = el.querySelectorAll('.button');
				if (tabButtons.length) {
					let tabs = el.querySelectorAll('.tab');

					tabButtons.forEach(tabButton => {
						listenerBoss.register(tabButton, 'click', e => {
							e.preventDefault();

							//remove the selected class from each tab button
							tabButtons.forEach(tb => {
								tb.classList.remove(selectedClass);
							});
							//add the selected class to this tab button
							tabButton.classList.add(selectedClass);

							//remove the selected class from each tab
							tabs.forEach(tab => {
								tab.classList.remove(selectedClass);
							});

							//add the selected class to the currently selected tab
							let tabId = tabButton.getAttribute('href');
							let selectedTab = el.querySelector(tabId);
							if (selectedTab) {
								selectedTab.classList.add(selectedClass);
								let tabHeading = selectedTab.querySelector('.tab-heading');
								if (tabHeading) {
									tabHeading.setAttribute('tabIndex', '-1');
									tabHeading.focus();
								}
							}
						});
					});

					tabButtons[0].click();
				}
			});
		}
	},
	destroy() {
		listenerBoss.removeAll();
	}
}
