import debounce from 'debounce'
import ListenerManager from './listenerManager'

let listenerBoss = new ListenerManager()
let initAttribute = 'data-video-ready'

export default {
	init(selector = '[data-video-cover-holder]') {
		let holderEls = document.querySelectorAll(selector)
		holderEls.forEach(holderEl => {
			if (!holderEl.hasAttribute(initAttribute)) {
				//add the attribute
				holderEl.setAttribute(initAttribute, 'y')

				let videoEl = holderEl.querySelector('video, iframe')

				if (videoEl) {
					let videoTag = videoEl.tagName.toLowerCase()
					let videoWidth = (holderEl.getAttribute('data-width') || 16) * 1
					let videoHeight = (holderEl.getAttribute('data-height') || 9) * 1
					let videoRatio = videoWidth / videoHeight

					const resizeVideo = () => {
						if (holderEl) {
							let box = holderEl.getBoundingClientRect()
							let actualWidth = box.width
							let actualHeight = box.height
							let actualRatio = actualWidth / actualHeight
							let newWidth
							let newHeight

							if (actualRatio < videoRatio) {
								newHeight = actualHeight
								newWidth = newHeight * videoRatio
							} else {
								newWidth = actualWidth
								newHeight = newWidth / videoRatio
							}

							//if this is a video tag, give it a little extra bleed
							if (videoTag === 'video') {
								let increaseRatio = 1.05
								newWidth = newWidth * increaseRatio
								newHeight = newHeight * increaseRatio
							}

							videoEl.style.width = Math.ceil(newWidth) + 'px'
							videoEl.style.height = Math.ceil(newHeight) + 'px'
						} else {
							;['DOMContentLoaded', 'load', 'resize'].forEach(function (eventName) {
								listenerBoss.remove(window, eventName, debounced)
							})
						}
					}

					const debounced = debounce(resizeVideo, 10, false)

						//watch
					;['DOMContentLoaded', 'load', 'resize'].forEach(function (eventName) {
						listenerBoss.register(window, eventName, debounced)
					})
					resizeVideo()
				}
			}
		})
	},
	destroy() {
		listenerBoss.removeAll()
	},
}
