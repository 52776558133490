import ListenerManager from './listenerManager';
let listenerBoss = new ListenerManager();

export default class ContentLightbox {
	_launcherSelector;
	_closeSelector;
	_openedClass;
	_onOpenCallback = function() {};
	_onCloseCallback = function() {};

	constructor({
            launcherSelector = '[data-content-lightbox]',
            closeSelector = '[data-content-lightbox-close]',
            openClass = 'contentLightbox--open',
            onOpen = function(){},
            onClose = function(){}
        } = {}) {
		this._launcherSelector = launcherSelector;
		this._closeSelector = closeSelector;
		this._openedClass = openClass;
		this._onOpenCallback = onOpen;
		this._onCloseCallback = onClose;
	}

	init() {
		let that = this;

		let els = document.querySelectorAll(this._launcherSelector);
		if (els.length) {
			els.forEach(el => {

				let id = el.getAttribute('data-content-lightbox');
				let lightbox = document.querySelector('#'+id);
				if (lightbox) {
					document.body.appendChild(lightbox);

					//open items
					listenerBoss.register(el, 'click', function(e) {
						e.preventDefault();
						lightbox.classList.add(that._openedClass);

						//call the callback
						if (that._onOpenCallback) {
							that._onOpenCallback();
						}

						lightbox.querySelectorAll(that._closeSelector).forEach(closeEl => {
							listenerBoss.register(closeEl, 'click', function (e) {
								e.preventDefault();
								lightbox.classList.remove(that._openedClass);

								//call the callback
								if (that._onCloseCallback) {
									that._onCloseCallback();
								}
							})
						});

						return false;
					})
				}
			});
		}
	}

	destroy() {
		listenerBoss.removeAll();
		let els = document.querySelectorAll(this._openClass);
		if (els.length) {
			els.forEach(el => {
				el.classList.remove(this._openClass);
			});

			if (this._onCloseCallback) {
				this._onCloseCallback();
			}
		}
	}
}
