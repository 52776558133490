import ListenerManager from './listenerManager';
let listenerBoss = new ListenerManager();

export default {
	init(selector = '[data-form]') {
		let forms = document.querySelectorAll(selector);
		let alreadyAddedHash = false;
		if (forms.length) {
			forms.forEach(form => {
				let canFormSubmit = true;

				//if there are form errors, scroll to the first form
				if (!alreadyAddedHash && form.getAttribute('data-form-errors') === 'y') {
					alreadyAddedHash = true;
					let formId = form.getAttribute('data-form-id');
					if (formId) {
						window.location.hash = formId;
					}
				}

				//custom validation fields
				let fields = form.querySelectorAll('[data-validity-message]');
				if (fields.length) {
					fields.forEach(field => {
						let validityMessage = field.getAttribute('data-validity-message');
						if (validityMessage) {
							listenerBoss.register(field, 'input', function (e) {
								if (field.validity.patternMismatch) {
									field.setCustomValidity(validityMessage);
								} else {
									field.setCustomValidity('');
								}
							});
						}
					})
				}

				//confirmation fields
				let confirmFields = form.querySelectorAll('[name$="-confirm"]');
				if (confirmFields.length) {
					confirmFields.forEach(confirmField => {
						//get the original field name
						let fieldName = confirmField.getAttribute('name').replace('-confirm', '');

						//try and get the original field
						if (fieldName) {
							let originalField = form.querySelector('[name="'+fieldName+'"]');
							if (originalField) {
								//we have an original field, let's add the handler
								const checkFields = function() {
									canFormSubmit = confirmField.value === originalField.value;
								};

								listenerBoss.register(originalField, 'keyup', checkFields);
								listenerBoss.register(confirmField, 'keyup', checkFields);

								listenerBoss.register(form, 'submit', e => {
									e.preventDefault();
									if (canFormSubmit) {
										form.submit();
									} else {
										alert('The '+fieldName+' and '+fieldName+' confirmation fields must match.');
									}
								});

								checkFields();
							}
						}
					});
				}
			});
		}
	},
	destroy() {
		listenerBoss.removeAll();
	}
}
