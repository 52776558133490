export default {
	init(selector = '[data-aos]') {
		let els = document.querySelectorAll(selector);
		if (els.length) {
			import(/*webpackChunkName: "aos-styles"*/ 'aos/dist/aos.css');
			import(/*webpackChunkName: "aos"*/ 'aos').then(AOS => {
				AOS.init();
				window.AOS = AOS;
			});
		}
	}
}
